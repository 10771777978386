import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Disclaimer = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Disclaimer" />

      <div className="container md:mx-auto mt-6">
        <h1 className="font-bold text-5xl text-center py-10">Disclaimer</h1>
        <div className="static-content">
          <h2 id="h-general-information">
            <strong>GENERAL INFORMATION</strong>
          </h2>
          <p>
            Snorkout (“company”, “I”, “we” or “us”) provides general educational
            information on various topics on this website as a public service,
            which should not be construed as professional, coaching,
            health/wellness, financial, travel, or legal advice. These are my
            personal opinions only.
          </p>
          <p>
            The term “you” refers to anyone who uses, visits and/or views the
            website.
          </p>
          <p>
            Please read this Disclaimer carefully, and I reserve the right to
            modify it at any time without notice.&nbsp;&nbsp;
            <strong>
              By visiting and using this website, you accept and agree to be
              bound by this Disclaimer along with our&nbsp;Terms and
              Conditions&nbsp;and&nbsp;Privacy Policy. Your continued use of our
              website, programs, products and/or services constitutes your
              acceptance of future changes and updates to this Disclaimer. You
              must not access or use our website if you do not wish to be bound
              by this Disclaimer.
            </strong>
          </p>
          <h2 id="h-disclaimers">
            <strong>DISCLAIMERS</strong>
          </h2>
          <h3 id="h-medical-health-disclaimer">
            <strong>Medical / Health Disclaimer</strong>
          </h3>
          <p>
            All content and information on this website including our programs,
            products and/or services is for informational and educational
            purposes only, does not constitute medical, psychological or health
            advice of any kind and we do not warrant that the information
            presented herein is free of any errors or omissions. We are not
            providing medical, health care, nutrition therapy or coaching
            services to diagnose, treat, prevent or cure any kind of physical
            ailment, mental or medical condition. &nbsp;A professional-client
            relationship with you is only formed after we have expressly entered
            into a written agreement with you that you have signed including our
            fee structure and other terms to work with you in a specific matter.
          </p>
          <p>
            Although we strive to provide accurate general information, the
            information presented here is not a substitute for any kind of
            professional advice, and you should not rely solely on this
            information. Always consult a professional in the medical and health
            area for your particular needs and circumstances prior to making any
            medical or health related decisions. For your health related
            questions, please seek the advice of a licensed physician or any
            other qualified health care provider immediately.
          </p>
          <h3 id="h-general-disclaimer">
            <strong>General Disclaimer</strong>
          </h3>
          <p>
            All content and information on this website including our programs,
            products and/or services is for informational and educational
            purposes only, does not constitute professional or coaching advice
            of any kind and does not establish any kind of professional-client
            relationship by your use of this website. A professional-client
            relationship with you is only formed after we have expressly entered
            into a written agreement with you that you have signed including our
            fee structure and other terms to work with you in a specific matter.
            Although we strive to provide accurate general information, the
            information presented here is not a substitute for any kind of
            professional advice, and you should not rely solely on this
            information. Always consult a professional in the area for your
            particular needs and circumstances prior to making any professional,
            legal, financial or tax related decisions.
          </p>
          <h2 id="h-affiliate-third-party-links-disclosure-and-disclaimer">
            <strong>
              AFFILIATE / THIRD-PARTY LINKS DISCLOSURE AND DISCLAIMER
            </strong>
          </h2>
          <p>
            We may partner with other businesses or become part of different
            affiliate marketing programs whose products or services may be
            promoted or advertised on the website in exchange for commissions
            and/or financial rewards when you click and/or purchase those
            products or services through our affiliate links.&nbsp;We will
            receive a commission if you make a purchase through our affiliate
            link at no extra cost to you.
          </p>
          <p>
            We may also recommend other products, services, coaches and
            consultants but no such reference is intended to be an endorsement
            or statement that such information provided is accurate. We
            recommend these based on our personal experiences but it is still
            your responsibility to conduct your own due diligence to ensure you
            have obtained complete accurate information about such product,
            services, coaches and consultants.
          </p>
          <p>
            We are a participant in the Amazon Services LLC Associates Program,
            which is an affiliate advertising program designed to provide a
            means for sites to earn advertising fees by advertising and linking
            to Amazon.com.
          </p>
          <p>
            These affiliate or third party relationships in no way compromise
            the integrity of the content, information, services and materials
            being presented to you here, and you are under no obligation to
            click on these affiliate links to purchase those products or
            services being offered. These affiliate programs are selected based
            on personal experiences and preferences.
          </p>
          <p>
            Although we provide these affiliate links on the website for your
            convenience, we have no control over these external websites and
            they are solely responsible for their own content and information
            presented. &nbsp;Therefore, Snorkout&nbsp;and/or its officers,
            employees, successors, shareholders, joint venture partners or
            anyone else working with us cannot be held liable or responsible for
            any content presented on these external websites and for any damages
            resulting from them.
          </p>
          <p>
            This disclosure policy applies to all affiliate links we share on
            our website, social media, emails, programs, products such as
            courses, ebooks, services and any other means of communication with
            you.
          </p>
          <h2 id="h-earnings-disclaimer-testimonials-and-other-disclaimers">
            <strong>
              EARNINGS DISCLAIMER, TESTIMONIALS AND OTHER DISCLAIMERS
            </strong>
          </h2>
          <p>
            We may disclose our income reports and success results of our
            current or former customers including product reviews and
            testimonials on the website from time to time. These income reports,
            product reviews and testimonials are accurate and strictly for
            informational purposes only.
          </p>
          <p>
            We share this information as&nbsp;examples to&nbsp;you but it does
            not serve as a guarantee or promise of any kind for your results and
            successes if you decide to use the same information, reviews,
            products, services, tips and techniques offered here.
          </p>
          <p>
            All the testimonials included on our websites, programs, products
            and/or services are real-world examples and stories of other
            people’s experiences with our programs, products and/or services.
            But they are not intended to serve as a guarantee that you will
            achieve the same or similar results. Each individual’s performance
            is different and your results will vary accordingly.
          </p>
          <p>
            You are encouraged to perform your own due diligence and research
            and are solely responsible for your earnings and results. Your
            earning potential and results are contingent upon your personal
            circumstances, abilities, experience, and skills. Therefore, you
            agree not to hold us and/or our officers, employees, successors,
            shareholders, joint venture partners or anyone else working with us
            liable for any of your successes or failures directly or indirectly
            related to the information, reports, reviews, products and/or
            services presented to you here.
          </p>
          <h2 id="h-sponsored-posts-reviews-disclaimer">
            <strong>SPONSORED POSTS / REVIEWS DISCLAIMER</strong>
          </h2>
          <p>
            We may include sponsored blog posts on our website from time to time
            for products or services we recommend or those that have been
            valuable in our personal experience or use.
          </p>
          <p>
            We may review different products, services, and other resources to
            provide reviews of books, services and any other recommendations to
            you. We may receive incentives, discounts, compensation or free
            products in exchange for our reviews and sponsored content. All such
            reviews and sponsored posts are solely our honest opinions made in
            good faith. You are always encouraged to perform your own due
            diligence prior to relying on them.
          </p>
          <p>
            We share this information as&nbsp;examples to&nbsp;you but it does
            not serve as a guarantee or promise of any kind for your results and
            successes if you decide to use the same information, reviews,
            products, services, tips and techniques offered here.
          </p>
          <p>
            You are encouraged to perform your own due diligence and research
            and are solely responsible for your decisions, purchases from our
            affiliate links, sponsored content and results. Your earning
            potential and results are contingent upon your personal
            circumstances, abilities, experience, and skills. Therefore, you
            agree not to hold us and/or our officers, employees, successors,
            shareholders, joint venture partners or anyone else working with us
            liable for any of your successes or failures directly or indirectly
            related to the information, reports, reviews, products and/or
            services presented to you here.
          </p>
          <h2 id="h-fair-use-disclaimer">
            <strong>FAIR&nbsp;USE DISCLAIMER&nbsp;</strong>
          </h2>
          <p>
            This website reviews products,&nbsp;including but not limited to
            posting of product images from other websites, logos of
            manufacturers. In doing so, no copyright is claimed for this kind of
            content on the website and to the extent that such material may
            appear to be infringed, we assert that such alleged infringement is
            permissible under the&nbsp;fair&nbsp;use principles of the U.S.
            copyright laws. If you believe any material has been used in an
            unauthorized manner, please contact us at contact@snorkout.com
            <em>.&nbsp;</em>
          </p>
          <h2 id="h-no-warranties">
            <strong>NO WARRANTIES</strong>
          </h2>
          <p>
            ALL CONTENT, INFORMATION, PRODUCTS AND/OR SERVICES ON THE WEBSITE
            ARE “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS OR
            WARRANTIES OF ANY KIND INCLUDING THE WARRANTIES OF MERCHANTABILITY
            OR FITNESS FOR ANY PURPOSE, EXPRESS OR IMPLIED TO THE FULL EXTENT
            PERMISSIBLE BY LAW. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
            AS TO THE CONTENT, INFORMATION, MATERIALS, PRODUCTS AND/OR SERVICES
            PROVIDED ON THIS WEBSITE. COMPANY MAKES NO WARRANTIES THAT THE
            WEBSITE WILL PERFORM OR OPERATE TO MEET YOUR REQUIREMENTS OR THAT
            THE INFORMATION PRESENTED HERE WILL BE COMPLETE, CURRENT OR
            ERROR-FREE. COMPANY DISCLAIMS ALL WARRANTIES, IMPLIED AND EXPRESS
            FOR ANY PURPOSE TO THE FULL EXTENT PERMITTED BY LAW.
          </p>
          <h2 id="h-limitation-of-liability">
            <strong>LIMITATION OF LIABILITY</strong>
          </h2>
          <p>
            You agree that under no circumstances, we and/or our officers,
            employees, successors, shareholders, joint venture partners or
            anyone else working with us shall be liable for any direct,
            indirect, incidental, consequential, equitable, special, punitive,
            exemplary or any other damages resulting from your use of this
            website including but not limited to all the content, information,
            products, services and graphics presented here.
          </p>
          <p>
            You expressly agree that your use of the website is at your sole
            risk and that you are solely responsible for the accuracy of the
            personal and any information you provide, outcome of your actions,
            personal and business results, and for all other use in connection
            with the website.
          </p>
          <p>
            You also expressly agree that we and/or our officers, employees,
            successors, shareholders, joint venture partners or anyone else
            working with us shall not be liable to you for any damages resulting
            from 1) any errors or omissions on the website, delay or denial of
            any products or services, failure of performance of any kind,
            interruption in the operation and your use of the website, website
            attacks including computer virus, hacking of information, and any
            other system failures; 2) any loss of income, use, data, revenue,
            profits, business or any goodwill related to the website; 3) any
            theft or unauthorized access by third party of your information from
            the website regardless of our negligence; and 4) any use or misuse
            of the information, products and/or services offered here.
          </p>
          <p>
            This limitation of liability shall apply whether such liability
            arises from negligence, breach of contract, tort or any other legal
            theory of liability. You agree that we provide no express or implied
            guarantees to you for the content presented here, and you accept
            that no particular results are being promised to you here.
          </p>
          <h2 id="h-indemnification">
            <strong>INDEMNIFICATION</strong>
          </h2>
          <p>
            You agree to indemnify and hold the Company and/or its officers,
            employees, successors, shareholders, joint venture partners or
            anyone else working with us harmless from all losses, claims,
            damages, demands, actions, suits, proceedings or judgments,
            including costs, expenses and reasonable attorneys’ fees
            (“Liabilities”) assessed against or otherwise incurred by you
            arising, in whole or in part, from: (a) actions or omissions,
            whether done negligently or otherwise, by you, your agents,
            directors, officers, employees or representatives; (b) all your
            actions and use of our websites including purchasing programs,
            products and services; (c) violation of any laws, rules, regulations
            or ordinances by you; or (d) violation of any&nbsp;terms and
            conditions&nbsp;of this website by you or anyone related to you; e)
            infringement by you or any other user of your account of any
            intellectual property or other rights of anyone. The company will
            notify you promptly of any such claims or liability and reserves the
            right to defend such claim, liability or damage at your expense. You
            shall fully cooperate and provide assistance to us if requested,
            without any cost, to defend any such claims.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Disclaimer

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
